import React, { useEffect } from 'react';

function InfoPage() {
  useEffect(() => {
    document.title = 'CLM Components Status';
  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      Under Construction
    </div>
  );
}

export default InfoPage;
